'use client';
import { isMobile } from '@util/index';
import {
  CarouselContent,
  CarouselItem,
  CarouselNew,
  CarouselNext,
  CarouselPrevious,
} from './CarouselNew';
import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface CarouselProps {
  items: React.ReactNode[];
  basis?: string;
  loading?: boolean;
  emptyMessage?: React.ReactNode;
  incrementBy?: number;
  spacing?: number;
  slideClassName?: string;
  showStepper?: boolean;
}

const CarouselComponent = ({
  items,
  basis = 'basis-1/2 sm:basis-1/5',
  loading,
  emptyMessage,
  incrementBy,
  spacing,
  slideClassName,
  showStepper = false,
}: CarouselProps) => {
  const [api, setApi] = useState<any>();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (!api) {
      return;
    }

    api.on('select', () => {
      setActiveIndex(api.selectedScrollSnap());
    });
  }, [api]);

  const scrollTo = useCallback(
    (index: number) => {
      api?.scrollTo(index);
    },
    [api]
  );

  const handleSlideChange = (index: number) => {
    setActiveIndex(index);
  };

  const stepperVariants = {
    active: {
      scale: 1.2,
      backgroundColor: '#000',
      transition: { duration: 0.3 },
    },
    inactive: {
      scale: 1,
      backgroundColor: '#D1D5DB',
      transition: { duration: 0.3 },
    },
  };

  return (
    <>
      <CarouselNew
        opts={{
          slidesToScroll: incrementBy ?? isMobile() ? 2 : 5,
        }}
      >
        <CarouselContent
          className={`${spacing ? `-ml-${spacing}` : ''} justify-start`}
        >
          {!loading ? (
            items.length ? (
              items.map((item, index) => {
                return (
                  <CarouselItem
                    className={`${basis} ${
                      spacing ? `ml-${spacing}` : ''
                    } ${slideClassName}`}
                    key={index}
                  >
                    {item}
                  </CarouselItem>
                );
              })
            ) : (
              <CarouselItem>{emptyMessage}</CarouselItem>
            )
          ) : (
            <>
              <CarouselItem className={basis} key={0}>
                <ProductCardSkeleton />
              </CarouselItem>
              <CarouselItem className={basis} key={1}>
                <ProductCardSkeleton />
              </CarouselItem>
              <CarouselItem className={basis} key={2}>
                <ProductCardSkeleton />
              </CarouselItem>
              <CarouselItem className={basis} key={3}>
                <ProductCardSkeleton />
              </CarouselItem>
              <CarouselItem className={basis} key={4}>
                <ProductCardSkeleton />
              </CarouselItem>
            </>
          )}
        </CarouselContent>
        <CarouselNext />
        <CarouselPrevious />
      </CarouselNew>

      {showStepper && (
        <div className="mt-4 flex justify-center">
          {items.map((_, index) => (
            <motion.button
              key={index}
              onClick={() => scrollTo(index)}
              variants={stepperVariants}
              animate={activeIndex === index ? 'active' : 'inactive'}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="mx-1 h-3 w-3 rounded-full"
            />
          ))}
        </div>
      )}
    </>
  );
};

export default CarouselComponent;
