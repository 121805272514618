'use client';
import { EyeIcon } from '@c/icons';
import { PlayIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import Chip from '@ui/Chip';
import { getItem, logEvent } from '@util/analytics';
import { getBidsCountByProductId } from '@util/firestore/bid/bid.service';
import { getProductViews } from '@util/firestore/products';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import useCountdown from '@util/hooks/useCountdown';
import useIntersectionObserver from '@util/hooks/useIntersectionObserver';
import { formatCurrency } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import QuickViewModal from 'features/shop/components/QuickViewModal';
import { OrderItemDocument } from 'models/order';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

const ProductVideoCard = ({
  product,
  list_id,
  list_name,
  attribution,
}: {
  product: ProductDocument;
  list_id?: string;
  list_name?: string;
  attribution: OrderItemDocument['attribution'];
}) => {
  const [quickViewOpen, setQuickViewOpen] = useState(false);
  const { setAttribution } = useAttributionContext();
  const pathname = usePathname();
  const { userDoc } = useAuth();
  const { filters } = useSearch();
  return (
    <div className="product-viewer-card group relative flex h-full max-h-[50rem]  w-[65vw] min-w-[16rem] flex-col overflow-hidden rounded-brand  shadow-md lg:max-h-none lg:w-[28rem] lg:max-w-none">
      <div className="pointer-events-none absolute inset-0 z-10 bg-black/40"></div>
      <Link
        href={getProductSlug(product)}
        onClick={() => {
          setAttribution(product.id, attribution);
          logEvent('select_item', {
            items: [getItem(product)],
            item_list_id: list_id ?? pathname,
            item_list_name: list_name ?? pathname,
          });
          if (pathname?.includes('search') && userDoc) {
            trackUserInteraction({
              uid: userDoc.uid,
              pid: product.id,
              interaction: 'search_clicked',
            });
          }
          if (pathname?.includes('search') && filters?.term) {
            trackUserSearch({
              ...filters,
              product_clicked: product.id,
            });
          }
          // algoliaInsights('clickedObjectIDs', {
          //   index: process.env.NEXT_PUBLIC_IS_STAGING ? 'products' : 'products',
          //   eventName: 'product card clicked',
          //   objectIDs: [product.id],
          // });
        }}
        className="h-full"
      >
        <ProductVideo product={product} />
        <ProductInfo product={product} />
      </Link>
      {product.category !== 'Dirt Bikes' && !product.is_auction && (
        <div className="z-20 hidden opacity-0 delay-0 duration-200 ease-linear group-hover:opacity-100 lg:block">
          <div className="flex w-full justify-center">
            <button
              className="text-shadow absolute bottom-[18rem] flex gap-[0.8rem] rounded-full bg-black p-4 text-white transition-transform  hover:scale-110 "
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setQuickViewOpen(true);
                setAttribution(product.id, attribution);
                logEvent('open_quick_view', {
                  items: [getItem(product)],
                });
              }}
            >
              QuickView
              <EyeIcon />
            </button>
          </div>
        </div>
      )}
      <QuickViewModal
        product={product}
        isOpen={quickViewOpen}
        dismiss={() => setQuickViewOpen(false)}
      />
    </div>
  );
};

const ProductInfo = ({ product }: { product: ProductDocument }) => {
  const { data: bidCount } = useQuery({
    queryKey: ['bidCount', product.id],
    queryFn: () => getBidsCountByProductId(product.id),
    enabled: !!product.is_auction,
  });
  return (
    <div className="product-viewer-text absolute bottom-0  z-20 flex w-full grow flex-col justify-between p-[0.8rem] text-white lg:gap-[0.8rem]">
      <span className="line-clamp-1 text-[1.8rem] font-semibold lg:line-clamp-2">
        {product.title}
      </span>
      {!product.is_auction && (
        <span className="line-clamp-1 text-[1.6rem] font-medium ">
          {product.category1 === 'Boots' || product.category1 === 'Helmets'
            ? product.category1
            : product.category2 ??
              product.location_display ??
              product.category1 ??
              product.category}
        </span>
      )}

      {!product.is_auction && (
        <span className="line-clamp-1 text-[1.6rem] font-medium ">
          {product.size?.letter ? `Size: ${product.size?.letter}` : ''}{' '}
          {product.size?.number
            ? `${product.size?.letter ? '/ ' : ''} Size: ${
                product.size?.number
              }`
            : ''}
          {product.variations ? 'Multiple sizes' : ''}
        </span>
      )}
      {product.is_auction && (
        <div className="flex w-[8rem] flex-col gap-[0.4rem]">
          <Chip text="Auction" color="gray" />
          <span className="whitespace-nowrap text-[1.4rem] font-medium ">
            {bidCount !== undefined ? `(${bidCount} bids)` : ''}
          </span>
        </div>
      )}
      <Price product={product} />
      {product.on_sale && (
        <DiscountBadge
          discount={Math.round(
            ((product.og_price - product.price) / product.og_price) * 100
          )}
        />
      )}
    </div>
  );
};

const ProductAuctionCountdown = ({ product }: { product: ProductDocument }) => {
  const isStarted = product.start_time! < Date.now();
  const timeRemaining = useCountdown(
    product.start_time!,
    product.end_time!,
    isStarted ? 'end' : 'start'
  );
  return (
    <div
      className={`absolute bottom-0 flex w-full items-center gap-[0.4rem] ${
        isStarted ? 'bg-brand-green' : 'bg-brand-secondary'
      } p-[0.8rem] text-center text-white`}
    >
      <div className="flex  w-full items-center justify-center gap-[0.8rem] rounded-b-2xl">
        <span
          suppressHydrationWarning
          className="w-full text-[1.2rem] font-medium sm:text-[1.6rem]"
        >
          {isStarted ? 'Ends in ' : 'Starts in '}
          {timeRemaining.days > 0 && `${timeRemaining.days}d `}
          {timeRemaining.hours}h {timeRemaining.minutes}m{' '}
          {timeRemaining.seconds}s
        </span>
      </div>
    </div>
  );
};

const Price = ({ product }: { product: ProductDocument }) => {
  let fullPrice = product.price;

  return (
    <div className="flex gap-[0.4rem]">
      <span className={`text-[1.8rem] font-semibold`}>
        {formatCurrency(fullPrice)}
      </span>
      {product.on_sale && (
        <span
          className="text-[1.6rem] font-normal text-black line-through"
          style={{
            textShadow: 'initial',
          }}
        >
          {formatCurrency(product.og_price)}
        </span>
      )}
    </div>
  );
};

const SoldOutOverlay = () => (
  <div className="absolute inset-0 mx-auto flex h-[5rem] items-center justify-center">
    <span className="absolute z-10 text-[2.2rem] font-semibold tracking-wider text-white">
      SOLD
    </span>
    <div className="absolute h-full w-full bg-brand-secondary opacity-80" />
  </div>
);

const ProductVideo = ({ product }: { product: ProductDocument }) => {
  const { data: views } = useQuery({
    queryKey: ['views', product.id],
    queryFn: () => getProductViews(product.id),
    enabled: !!product.id,
  });

  const ref = useRef<HTMLVideoElement | null>(null);
  const entry = useIntersectionObserver(ref as any, {
    threshold: 0.25,
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      ref.current &&
      !ref.current.src &&
      product.video?.full
    ) {
      // ref.current.src = product.video?.full;
      setTimeout(() => {
        if (ref.current && product.video?.full) {
          ref.current.src = product.video?.full;
        }
      }, Math.random() * 1000);
      setLoaded(true);
    }
    if (ref.current?.src && !entry?.isIntersecting) {
      ref.current?.pause();
    } else if (ref.current?.src && entry?.isIntersecting) {
      ref.current?.play();
    }
  }, [entry?.isIntersecting, product.video?.full]);

  const viewsCount = views?.views ?? 0;
  // format 1,000+ to 1k , 1,300 to 1.3k etc
  const viewsFormatted =
    viewsCount > 999 ? `${(viewsCount / 1000).toFixed(1)}K` : viewsCount;
  if (!product.video) return null;
  return (
    <>
      <div className="relative h-full w-full shrink-0 overflow-clip lg:w-full">
        <video
          ref={ref}
          id={product.id + `video`}
          autoPlay
          loop
          muted
          playsInline
          className={`h-full w-full object-cover ${
            !loaded ? `min-h-[50rem] animate-pulse bg-brand-light-gray` : ''
          }`}
        />

        <div className="font-sembiold absolute right-[1rem] top-[0.8rem] z-20 flex gap-[0.8rem] text-white">
          <PlayIcon fill={'none'} stroke="white" width={24} height={24} />
          <span className="text-[1.6rem] font-medium">{viewsFormatted}</span>
        </div>
        {product.out_of_stock && !product.is_auction && <SoldOutOverlay />}
        {product.is_auction &&
          product.end_time! > Date.now() &&
          !product.is_draft && <ProductAuctionCountdown product={product} />}
      </div>
    </>
  );
};

const DiscountBadge = ({ discount }: { discount: number }) => {
  return (
    <div className="absolute bottom-[0.8rem] right-[1rem] z-20 flex items-center justify-center rounded-[0.4rem] bg-brand-secondary px-[1.6rem] py-[0.8rem]">
      <span className="text-[1.5rem] font-semibold text-white">
        {discount | 0}% off
      </span>
    </div>
  );
};
export default ProductVideoCard;
